.inputHeight{
    height: 57px !important;
    border: 1px solid #ccc;
    border-radius: 5px;
    
    background-color: #fff;
    border: 1px solid #ccc;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;

}

.inputHeight2 {
    overflow-y: auto;
    max-height: 42px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    padding-left: 5px;
    padding-top: 5px;
 }
 
 .inputHeight2::-webkit-scrollbar {
    width: 12px; 
 }
 
 .inputHeight2::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 6px; 
 }
 .inputHeight2::-webkit-scrollbar-thumb:hover {
    background-color: #555; 
 }
 
 .inputHeight2::-webkit-scrollbar-track {
    background-color: #f1f1f1; 
 }
 