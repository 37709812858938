#customPagination .page-item.active .page-link {
    z-index: 3;
    color: rgb(22, 74, 232);
    background-color: transparent;
    border-color: transparent;
}

#customPagination .page-item .page-link {
    z-index: 3;
    color: grey;
    background-color: transparent;
    border-color: transparent;
}