.main_container {
  /* width: 1618px; */
  height: 333px;
  flex: none;
  order: 0;
  flex-grow: 0;
  left: 0px;
  top: 0px;
  background: #0067b8;
  border-radius: 4px;
}

.main_container_1 {
  height: 246px;
  flex: none;
  order: 0;
  flex-grow: 0;
  left: 0px;
  top: 0px;
  background: #ff5f57;
  border-radius: 4px;
}

.main_container-overview {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  /* width: 1038px; */
  height: 250px;
  left: 130px;
  right: 280px !important;
  top: 150px;
}

.main_container-overview_logo_name {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 267px;
  height: 80px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_container-overview_logo {
  width: 80px;
  height: 80px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_container-overview_logoStyle {
  position: absolute;
  width: 56.8px;
  height: 56.8px;
  left: 11.2px;
  top: 12px;
  z-index: 2;
}

.main_container-overview_logoStyle-back {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

.main_container-overview_name {
  width: 172px;
  height: 38px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  line-height: 38px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.main_container-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  /* width: 674px; */
  height: 35px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_container-content_heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  /* width: 674px; */
  height: 35px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_container-content_heading_text {
  width: 674px;
  height: 35px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 35px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}



.main_container-content_sub-heading {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  width: 1038px;
  height: 105px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.main_container-content_sub-heading_text {
  width: 1038px;
  height: 105px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}



.main_container-key_points_style {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1px 5px;
  /* width: 66px; */
  height: 23px;
  background: #dcedff;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_container-key_points_style_text {
  /* width: 56px; */
  height: 21px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #1b1a1a;
  flex: none;
  order: 0;
  flex-grow: 0;
}

/* Job card */

.main_job_container {
  /* padding: 12px; */
  margin-left: 280px;
  margin-right: 280px;
}

.main_job_container-search {
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_job_container-count {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #1b1b1b;
  opacity: 0.8;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_cardClass {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 10px;
}

.main_cardClass-frame {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 0px 24px;
  /* gap: 816px; */
  height: 57px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.main_cardClass-frame-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  height: 57px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_cardClass-frame-left_jobHeading {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  height: 25px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_cardClass-frame-left_jobHeading-text {
  height: 25px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_cardClass-frame-left_jobDetails {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  width: 416px;
  height: 24px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.main_cardClass-frame-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  padding: 0px 24px 0px 0px;
  grid-gap: 4px;
  gap: 4px;
  /* width: 148px; */
  height: 24px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.main_cardClass-frame-right_btn {
  display: flex;
  width: 124px;
  height: 30px;
  flex: none;
  order: 1;
  flex-grow: 0;
  position: absolute;
  background: #7fba00;
  border-radius: 4px;
  justify-content: center;
}

.main_cardClass-frame-right_text {
  position: absolute;
  width: 61px;
  height: 21px;
  left: 31px;
  top: 2px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

/* JobApplyComponent style */

.main_job_requirements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  grid-gap: 10px;
  gap: 10px;
  /* width: 906px; */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 10vw;
  margin-right: 29vw;
}

.main_job_requirements_part_1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 100%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_job_requirements_part_2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 100%;
  min-height: auto;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.main_apply_container {
  position: absolute;
  left: 73vw;
  top: 245px;
}

/* ____________________________________________MEDIA QUERIES FOR APPLY NOW BUTTON _____________________________________________________________________ */
@media screen and (max-width: 1200px) {
  .main_apply_container_apply {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    position: absolute;
    width: 200px !important;
    /* height: 349px; */
    left: 0px;
    top: 0px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 16%);
    border-radius: 4px;
  }
  .main_apply_container_apply_btn1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 38px;
    grid-gap: 10px;
    gap: 10px;
    width: 172px !important;
    height: 40px;
    background: #7fba00;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .line {
    width: 170px !important;
    height: 0px;
    border: 1px solid #bdbdbd;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

@media screen and (max-width: 800px) {
  

  .main_container-overview {
    left: 6px !important;
}
  .main_container-key_points_style_text {
    /* width: 56px; */
    height: 21px;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 10px !important;
    line-height: 21px;
    color: #1b1a1a;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .main_job_requirements {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    grid-gap: 10px;
    gap: 10px;
    /* width: 906px; */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 1vw;
    margin-right: 1vw;
  }
  .main_apply_container_apply {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    width: 200px !important;
    /* height: 349px; */
    left: 0px;
    top: 0px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 16%);
    border-radius: 4px;
  }
  .main_apply_container_apply_btn1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 38px;
    grid-gap: 10px;
    gap: 10px;
    width: 172px !important;
    height: 40px;
    background: #7fba00;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .line {
    width: 170px !important;
    height: 0px;
    border: 1px solid #bdbdbd;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .dividerSpace {
    width: 500px !important;
    height: 0px;
    border: 1px solid #bdbdbd;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

@media screen and (max-width: 700px) {
  .main_container_1 {
    height: 170px !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    left: 0px;
    top: 0px;
    background: #ff5f57;
    border-radius: 4px;
}
  .main_apply_container_apply {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 16px;
    position: relative !important;
    left: 6vw !important;
    top: 15px !important;
    width: 100% !important;
    right: 28vw !important;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 16%);
    border-radius: 4px;
}
.main_apply_container_apply_btn1 {
  width: 250px !important;
}
}

@media screen and (max-width:480px) {
  

.main_container-content {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  height: 35px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.main_container-content_heading {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  height: 35px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.main_container-content_heading_text {
  height: 35px;
  /* width: 100%; */
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 35px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.main_container-key_points_style_text {
  /* width: 56px; */
  height: 21px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 8px !important;
  line-height: 21px;
  color: #1b1a1a;
  flex: none;
  order: 0;
  flex-grow: 0;
}
}

@media screen and (max-width:360px) {
  .main_container-key_points_style_text {
    /* width: 56px; */
    height: 21px;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 6px !important;
    line-height: 21px;
    color: #1b1a1a;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

.main_container-key_points_style {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1px 3px;
  /* width: 66px; */
  height: 23px;
  background: #dcedff;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
}

.main_apply_container_apply {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  position: absolute;
  width: 300px;
  /* height: 349px; */
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}

.main_apply_container_apply_btn1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 38px;
  gap: 10px;
  width: 270px;
  height: 40px;
  background: #7fba00;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.main_apply_container_apply_btn2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 38px;
  gap: 4px;
  width: 270px;
  height: 40px;
  border: 1px solid #0077b5;
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;
  background: none;
  margin-top: 10px;
}

.main_apply_container_apply_btn3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 38px;
  gap: 4px;
  width: 270px;
  height: 40px;
  border: 1px solid #7fba00;
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;
  background: none;
  margin-top: 10px;
}

.main_apply_container_apply_btn2_linkedin {
  width: 66px;
  height: 25px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #0077b5;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.main_apply_container_apply_btn_refer {
  height: 25px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #7fba00;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.line {
  width: 270px;
  height: 0px;
  border: 1px solid #bdbdbd;
  flex: none;
  order: 0;
  flex-grow: 0;
}


@media only screen and  (max-height: 800px)  {


  .share_via {
    margin-top: 200px;
  }
}