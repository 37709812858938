/*Fix for Side Nav Bar Height*/
/* #Nav {
    display:block;
    height:100vh !important;
  } */
.react-tabs__tab-list {
  /* border-bottom: 1px solid #aaa; */
  margin: 0 0 0px;
  padding: 0;
}

.styled-input {
  border: 1px solid #ccc;
  /* Adjust styles as needed */
  border-radius: 0.25rem;
  width: 100%;
  /* Add any other desired styles */
}

.border-radius {
  border-radius: 4px;
}

#updateLocation .card {
  background-color: white;
  padding: 0px;
  margin: 0px;
}

#updateLocation .bg-gray4 {
  background-color: white;
  padding: 0px;
}

#updateLocation .card-body {
  padding: 0px;
  margin: 0px;
  background-color: white;
}

#updateLocation .mx-2 {
  margin: 0px;
}

#updateLocation .col-lg-12 {
  margin: 0px;
  padding: 0px;
  background-color: none;
}

#addjobtype-2 {
  margin-top: 10px;
}

.addjob-sunmittal {
  height: 100px;
}

.react-tel-input .form-control {
  width: 100%;
  height: calc(3.5rem + 2px);
}

.option {
  font-size: 12px;
  text-transform: capitalize;
}

.Sspace {
  padding: 10px;
}

.custom-date-input {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
  padding-left: 0.625rem;
  z-index: 999;
}

.assessment-interview {
  height: 58px;
  padding: 15px !important;
  font-size: 1rem;
  font-weight: 400;
  background-color: #fff;
}

.search-results {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
}

.search-results li {
  font-style: normal;
  font-weight: lighter;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #ffff;
}

.search-results li:hover {
  background-color: #f1f1f1;
}

.downloaddots {
  color: #0f0f0f;
  font-family: "Noto Sans", sans-serif;
  font-display: swap;
  font-size: 0.85rem;
  cursor: pointer;
}

.downloaddots a {
  color: #00162e;
  font-family: "Noto Sans", sans-serif;
  font-display: swap;
  font-size: 0.85rem;
}

.candidatemenu {
  padding: 0px;
  margin: 0px;
}

.candidatemenu ul {
  padding: 0px;
  margin: 0px;
}

.candidatemenu ul li {
  padding: 0px;
  margin: 0px;
  color: #00162e;
  font-family: "Noto Sans", sans-serif;
  font-display: swap;
  font-size: 0.9rem;
  display: block;
}

.candidatemenu ul li a {
  padding: 5px 0px 2px 0px;
  margin: 0px;
  color: #0f0f0f;
  font-family: "Noto Sans", sans-serif;
  font-display: swap;
  font-size: 12px;
  display: block;
}

.candidatemenu ul li a:hover {
  text-decoration: none;
  color: #014283;
  background-color: #e9ecef;
}

.candidatemenu ul li a span {
  padding: 10px 15px 0px 0px;
  margin: 0px;
  color: #1b1b1b;
  font-family: "Noto Sans", sans-serif;
  font-display: swap;
  font-size: 0.8rem;
}

.selection-cell {
  width: 5%;
}

/* .react-datepicker {
	width: 136%;
} */

/* custom css for wave icon  */
.wave {
  animation-name: wave-animation;
  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;
  /* Change to speed up or slow down */
  animation-iteration-count: infinite;
  /* Never stop waving :) */
  transform-origin: 70% 70%;
  /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(14deg);
  }

  /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }

  30% {
    transform: rotate(14deg);
  }

  40% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(0deg);
  }

  /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans|Pinyon+Script|Rochester");

.sans {
  font-family: "Pinyon Script", cursive;
}

.bold {
  font-weight: bold;
}

.block {
  display: block;
}

.underline {
  border-bottom: 1px solid #777;
  padding: 5px;
  margin-bottom: 15px;
}

.margin-0 {
  margin: 0;
}

.padding-0 {
  padding: 0;
}

.pm-empty-space {
  width: 100%;
}

.dateeee {
  margin-top: 100px;
}

/* body {
  padding: 20px 0;
  background: rgb(241, 85, 85);
} */
.pm-certificate-container {
  position: relative;
  width: 800px;
  height: 600px;
  background-color: rgb(253, 87, 87);
  padding: 30px;
  color: #333;
  font-family: "Open Sans", sans-serif;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.outer-border {
  width: 794px;
  height: 594px;
  position: absolute;
  left: 50%;
  margin-left: -397px;
  top: 50%;
  margin-top: -297px;
  border: 2px solid #fff;
}

.inner-border {
  width: 730px;
  height: 530px;
  position: absolute;
  left: 50%;
  margin-left: -365px;
  top: 50%;
  margin-top: -265px;
  border: 2px solid #fff;
}

.pm-certificate-border {
  position: relative;
  width: 720px;
  height: 520px;
  padding: 0;
  border: 1px solid #e1e5f0;
  background-image: url("../assets/images/certificateBackground.png");
  z-index: 0.1;
  background-repeat: no-repeat;
  left: 50%;
  margin-left: -360px;
  top: 50%;
  margin-top: -260px;
}

.pm-certificate-block {
  width: 650px;
  height: 200px;
  position: relative;
  left: 50%;
  margin-left: -325px;
  top: 70px;
  margin-top: 0;
}

.pm-certificate-header {
  margin-bottom: 10px;
}

.pm-certificate-title {
  position: relative;
  top: 40px;
}

h2 {
  font-size: 34px !important;
}

.pm-certificate-body {
  padding: 20px;
}

.high5logo {
  object-fit: contain;
  height: 100px;
}

.pm-name-text {
  font-size: 20px;
}

.pm-earned {
  margin-top: 15px;
  margin-bottom: 5px;
}

.completion {
  margin-bottom: 10px;
}

.pm-earned-text {
  font-size: 20px;
}

.pm-credits-text {
  font-size: 15px;
}

.pm-course-title {}

.pm-earned-text {
  font-size: 20px;
}

.pm-credits-text {
  font-size: 15px;
}

.pm-certified {
  font-size: 12px;
}

.date {
  margin-top: 15px;
}

.underline {
  margin-bottom: 5px;
}

.pm-certificate-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 650px;
  height: 100px;
  position: relative;
  left: 50%;
  margin-left: -325px;
  bottom: -105px;
}

.text-editor {
  font-family: inherit;
}

.Hover-Dropdown {
  z-index: 100;
}

.react-datepicker-popper {
  z-index: 100;
}

.RichTextEditor__editor___1QqIU {
  font-family: inherit;
}

.react-pagination-table {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  margin-bottom: 20px;
  width: 80%;
  margin: auto;
}

.react-pagination-table__sub-title {
  float: right;
}

.react-pagination-table__title,
.react-pagination-table__sub-title {
  display: inline-block;
  font-size: 18px;
  margin: 0;
  line-height: 1;
  font-weight: 100;
  padding: 20px 20px 10px;
}

.react-pagination-table__header {
  background: #e8e8e8;
}

.react-pagination-table__table {
  text-align: center;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  border: 1px solid #ddd;
}

.pagination-status {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
}

.pagination-status__item {
  display: inline;
}

.pagination-status .pagination-status__btn {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.pagination-status .pagination-status__btn:focus {
  outline: none;
}

.pagination-status .pagination-status__btn--active,
.pagination-status .pagination-status__btn:hover {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
  cursor: pointer;
}

.pagination-status .pagination-status__btn--disable,
.pagination-status .pagination-status__btn--disable:hover {
  color: #d0d0d0;
  cursor: default;
  border-color: #d0d0d0;
  background-color: #fff;
}

input[type="radio"] {
  display: none;
}

.individualLabel {
  position: relative;
  color: #ff8f73;
  font-size: 15px;
  border: 2px solid #ff8f73;
  padding: 10px 30px;
  border-radius: 0.25em;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.eventsLabel {
  position: relative;
  color: black;
  font-size: 15px;
  border: 1px solid #ced4da;
  padding: 10px 30px;
  border-radius: 0.25em;
  display: flex;
  align-items: center;
  cursor: pointer;
}

input[type="radio"]:checked+.individualLabel {
  background-color: #ff8f73;
  color: white;
}

input[type="radio"]:checked+.eventsLabel {
  background-color: white;
  color: black;
}

input[type="radio"]:hover+.eventsLabel {
  border-color: #009eff;
  color: black;
}

input[type="radio"]:checked+.individualLabel::before {
  height: 12px;
  width: 12px;
  border: 10px solid white;
  background-color: #ff8f73;
}

input[type="radio"]:checked+.eventsLabel::before {
  height: 12px;
  width: 12px;
  border: 10px solid #009eff;
  background-color: #009eff;
}

@media screen and (min-width: 365px) and (max-width: 410px) {

  .mobile-space {
    margin-top: 20px;
  }

  .mobile-space2 {
    margin-top: none;
  }

  .lastName {
    margin-top: 15px;
  }
}

@media screen and (min-width: 300px) and (max-width: 900px) {

  #logsCard {
    width: auto;
    overflow-x: scroll;
  }

}

@media screen and (min-width: 300px) and (max-width: 767px) {

  #customPagination .pageCol1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #customPagination .pageCol2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

@media screen and (min-width: 768px) {
  #customPagination .pageCol1 {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  #customPagination .pageCol2 {
    display: flex;
    justify-content: end;
    align-items: center;
  }
}

@media screen and (max-width: 450px) and (min-width: 405px) {

  .mobile-space {
    margin-top: 20px;
  }

  .mobile-space2 {
    margin-top: 18px;
  }

  .lastName {
    margin-top: 10px;
  }
}

/* Re-write style.css */
.main_container {
  /* width: 1618px; */
  height: 250px;
  flex: none;
  order: 0;
  flex-grow: 0;
  left: 0px;
  top: 0px;
  background: #0067b8;
  border-radius: 4px;
}

.main_container_1 {
  height: 246px;
  flex: none;
  order: 0;
  flex-grow: 0;
  left: 0px;
  top: 0px;
  background: #ff5f57;
  border-radius: 4px;
}

.main_container-overview {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  /* width: 1038px; */
  height: 250px;
  left: 130px;
  right: 280px !important;
  top: 150px;
}

.main_container-overview_logo_name {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 267px;
  height: 80px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_container-overview_logo {
  width: 80px;
  height: 80px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_container-overview_logoStyle {
  position: absolute;
  width: 56.8px;
  height: 56.8px;
  left: 11.2px;
  top: 12px;
  z-index: 2;
}

.main_container-overview_logoStyle-back {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

.main_container-overview_name {
  width: 172px;
  height: 38px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  line-height: 38px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.main_container-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  /* width: 674px; */
  height: 35px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_container-content_heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  /* width: 674px; */
  height: 35px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_container-content_heading_text {
  /* width: 674px; */
  height: 35px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 35px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_container-content_heading_text_1 {
  height: 35px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  line-height: 35px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_container-content_sub-heading {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  width: 1038px;
  height: 105px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.main_container-content_sub-heading_text {
  width: 1038px;
  height: 105px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_container-key_points {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  /* width: 421px; */
  height: 23px;
  flex: none;
  order: 1;
  flex-grow: 0;
  flex-wrap: wrap;
}

.main_container-key_points_style {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1px 5px;
  /* width: 66px; */
  height: 23px;
  background: #dcedff;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_container-key_points_style_text {
  /* width: 56px; */
  height: 21px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #1b1a1a;
  flex: none;
  order: 0;
  flex-grow: 0;
}

/* Job card */

.main_job_container {
  /* padding: 12px; */
  margin-left: 280px;
  margin-right: 280px;
}

.main_job_container-search {
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_job_container-count {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #1b1b1b;
  opacity: 0.8;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_cardClass {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 10px;
}

.main_cardClass-frame {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 0px 24px;
  /* gap: 816px; */
  height: 57px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.main_cardClass-frame-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  height: 70px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_cardClass-frame-left_jobHeading {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  height: 25px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_cardClass-frame-left_jobHeading-text {
  height: 25px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_cardClass-frame-left_jobDetails {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  width: 600px;
  height: 24px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.main_cardClass-frame-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  padding: 0px 24px 0px 0px;
  grid-gap: 4px;
  gap: 4px;
  /* width: 148px; */
  height: 24px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.main_cardClass-frame-right_btn {
  display: flex;
  width: 124px;
  height: 30px;
  flex: none;
  order: 1;
  flex-grow: 0;
  position: absolute;
  background: #7fba00;
  border-radius: 4px;
  justify-content: center;
}

.main_cardClass-frame-right_text {
  position: absolute;
  width: 61px;
  height: 21px;
  left: 31px;
  top: 2px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

/* JobApplyComponent style */

.main_job_requirements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  grid-gap: 10px;
  gap: 10px;
  /* width: 906px; */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 10vw;
  margin-right: 29vw;
}

.main_job_requirements_part_1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 100%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main_job_requirements_part_1a {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 60%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

@media (max-width: 800px) {
  .main_job_requirements_part_1a {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    width: 95%;
    min-height: auto;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

.main_job_requirements_part_2a {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 60%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

@media (max-width: 800px) {
  .main_job_requirements_part_2a {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    width: 95%;
    min-height: auto;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

@media (min-width: 800px) {
  .main_job_requirements_part_2a {
    display: none;
  }
}

.main_job_requirements_part_2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 100%;
  min-height: auto;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.main_apply_container {
  position: absolute;
  left: 73vw;
  top: -75px;
}

@media (max-width: 800px) {
  .main_apply_container {
    display: none;
  }
}

.refer_container {
  position: absolute;
  left: 73vw;
  top: 195px;
}

.join_our_community_container {
  position: absolute;
  left: 73vw;
  top: 45px;
}

.main_signup_container {
  position: absolute;
  left: 73vw;
  top: 45px;
}

@media (max-width: 700px) {

  .join_our_community_container,
  .refer_container {
    display: none;
  }

  .main_signup_container {
    position: static;

  }
}

@media screen and (max-width: 1282px) {


  .description {
    height: 570px !important;
  }
}

/* ____________________________________________MEDIA QUERIES FOR APPLY NOW BUTTON _____________________________________________________________________ */
@media screen and (max-width: 1200px) {

  .main_apply_container_apply {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    position: absolute;
    width: 200px !important;
    /* height: 349px; */
    left: 0px;
    top: 0px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 16%);
    border-radius: 4px;
  }

  .main_apply_container_apply_btn1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 38px;
    grid-gap: 10px;
    gap: 10px;
    width: 172px !important;
    height: 40px;
    background: #7fba00;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .line {
    width: 170px !important;
    height: 0px;
    border: 1px solid #bdbdbd;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

@media screen and (max-width: 800px) {
  .main_apply_container {
    position: absolute;
    left: 70vw;
    top: 245px;
  }

  .main_container-overview {
    left: 6px !important;
  }

  .main_container-key_points_style_text {
    /* width: 56px; */
    height: 21px;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 10px !important;
    line-height: 21px;
    color: #1b1a1a;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .main_job_requirements {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    grid-gap: 10px;
    gap: 10px;
    /* width: 906px; */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 1vw;
    margin-right: 1vw;
  }

  .main_apply_container_apply {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    width: 100% !important;
    /* height: 349px; */
    left: 0px;
    top: 0px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 16%);
    border-radius: 4px;
  }

  .main_apply_container_apply_btn1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 38px;
    grid-gap: 10px;
    gap: 10px;
    width: 172px !important;
    height: 40px;
    background: #7fba00;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .line {
    width: 170px !important;
    height: 0px;
    border: 1px solid #bdbdbd;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

@media (max-width: 460px) {
  .hide-on-small-screen {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .description {
    height: 400px !important;
  }

  .main_apply_container {
    position: relative !important;
    margin-left: 1vw !important;
    margin-right: 13vw;
    left: 0px !important;
    top: 0px !important;
    padding-left: 30px;
  }
}

@media screen and (min-width: 481px) and (max-width: 700px) {

  .main_container_1 {
    height: 170px !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    left: 0px;
    top: 0px;
    background: #ff5f57;
    border-radius: 4px;
  }

  .main_container-content_heading_text_1 {
    height: 35px;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 900;
    font-size: 20px !important;
    line-height: 35px;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .main_container-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    height: 35px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .main_container-content_heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;
    height: 35px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .main_container-content_heading_text {
    height: 35px;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 35px;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .main_apply_container_apply {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 16px;
    position: relative !important;
    left: 6vw !important;
    top: 15px !important;
    width: 100% !important;
    right: 28vw !important;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 16%);
    border-radius: 4px;
  }

  .main_apply_container_apply_btn1 {
    width: 250px !important;
  }
}

@media screen and (min-width: 361px) and (max-width: 480px) {
  .main_container-key_points {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 1px;
    gap: 1px;
    height: 23px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  /* .break{
    flex-basis: 100%;
  height: 0;
  } */
  .main_container-content_heading_text_1 {
    height: 35px;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 900;
    font-size: 16px !important;
    line-height: 20px;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .main_container-content {
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    height: 35px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .main_container-content_heading {
    display: flex;
    /* width: 100%; */
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;
    height: 35px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .main_container-content_heading_text {
    height: 35px;
    /* width: 100%; */
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 35px;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .main_container-key_points_style_text {
    /* width: 56px; */
    width: 100%;
    height: 21px;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 8px !important;
    line-height: 21px;
    color: #1b1a1a;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

@media screen and (max-width: 360px) {
  .main_container-content {
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    height: 35px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .break {
    flex-basis: 100%;
    height: 0;
  }

  .main_container-content_heading {
    display: flex;
    /* width: 100%; */
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;
    height: 35px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .main_container-content_heading_text {
    height: 35px;
    /* width: 100%; */
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 35px;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .main_container-content_heading_text_1 {
    height: 35px;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 900;
    font-size: 16px !important;
    line-height: 20px;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .main_container-key_points_style_text {
    /* width: 56px; */
    height: 21px;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 6px !important;
    line-height: 21px;
    color: #1b1a1a;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .main_container-key_points {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 1px;
    gap: 1px;
    height: 23px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .main_container-key_points_style {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 1px 3px;
    /* width: 66px; */
    height: 23px;
    background: #dcedff;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

.main_apply_container_apply {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  position: absolute;
  width: 300px;
  /* height: 349px; */
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}

.main_apply_container_apply_btn1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 38px;
  gap: 10px;
  width: 270px;
  height: 40px;
  background: #7fba00;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.main_apply_container_apply_btn2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 38px;
  gap: 4px;
  width: 270px;
  height: 40px;
  border: 1px solid #0077b5;
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;
  background: none;
  margin-top: 10px;
}

.main_apply_container_apply_btn3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 38px;
  gap: 4px;
  width: 270px;
  height: 40px;
  border: 1px solid #7fba00;
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;
  background: none;
  margin-top: 10px;
}

.main_apply_container_apply_btn2_linkedin {
  width: 66px;
  height: 25px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #0077b5;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.main_apply_container_apply_btn_refer {
  height: 25px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #7fba00;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.line {
  width: 270px;
  height: 0px;
  border: 1px solid #bdbdbd;
  flex: none;
  order: 0;
  flex-grow: 0;
}

/* Re - write footer.js */
@media screen and (max-width: 800px) {
  .main_footer {
    padding: 12px;
    margin-left: 1vw !important;
    margin-right: 1vw !important;
  }

  .main_footer2 {
    padding: 12px;
    margin-left: 1vw !important;
    margin-right: 1vw !important;
  }
}

.main_footer {
  padding: 12px;
  margin-left: 20vw;
  margin-right: 20vw;
}

.main_footer2 {
  padding: 12px;
  margin-left: 10%;
  margin-right: 29%;
}

.main_footer-powered {
  display: flex;
}

.main_footer-powered-poweredBy {
  /* position: absolute; */
  /* top: 4px; */

  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #1b1b1b;
}

.main_footer-powered-logo {
  margin-left: 9px;
  margin-top: -6px;
}

.main_footer-powered-logo-1 {
  bottom: 12.12%;
}

.main_footer-privacy {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  /* identical to box height, or 175% */
  /* Brand font */
  color: #1b1b1b;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.main_footer-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 12px;
  background: #0067b8;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.main_footer-frame {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px;
  /* gap: 104px; */
  flex: none;
  order: 2;
  flex-grow: 0;
}

.main_footer-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  /* width: 601px;
      height: 96px; */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.main_footer-right {
  /* width: 301px;
  height: 55px; */

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}

.main_footer-heading {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  line-height: 38px;
  background: #0067b8;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 16px;
  width: 67%;
  color: #ffffff;
}

@media (max-width: 800px) {
  .main_footer-heading {
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 900;
    font-size: 25px;
    line-height: 38px;
    background: #0067b8;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    padding: 16px;
    width: 95%;
    color: #ffffff;
  }
}

.main_footer-sub_heading {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}

.main_footer-button_style {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  width: 267px;
  line-height: 24px;
  /* position: absolute; */
  background: #7fba00 !important;
  border-radius: 27.5px;
}

@media (max-width: 660px) {
  .main_footer-button_style {
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 167px;
    line-height: 24px;
    /* position: absolute; */
    background: #7fba00 !important;
    border-radius: 27.5px;
  }
}

.main_frame {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .main_frame {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

/* candidate profile css */
.activeTabCandidate {
  border-left: 6px solid #e74c3c;
}

.inActiveTabCandidate {
  border-left: 6px solid transparent;
  color: #6c757d;
}

.inActiveTabCandidate:hover {
  border-left: 6px solid transparent;
  color: #e74c3c;
  cursor: pointer;
  --bs-text-opacity: 1;
}

.activeTabCandidate1200 {
  border-bottom: 6px solid #e74c3c;
}

.inActiveTabCandidate1200 {
  border-bottom: 6px solid transparent;
  color: #6c757d;
}

.inActiveTabCandidate1200:hover {
  border-bottom: 6px solid transparent;
  color: #e74c3c;
  cursor: pointer;
  --bs-text-opacity: 1;
}

.mainCandidate {
  width: 75%;
}

@media screen and (max-width: 400px) {
  .mainCandidate {
    width: 100%;
  }
}

.fontSizeChange {
  font-size: 14px;
  font-weight: normal;
}

.dot::after {
  content: "\2022";
  float: left;
  line-height: 20px;
  color: #cccccc;
  margin: 0 10px;
}

.pointer {
  cursor: pointer;
}

/* Styles for the parent container */
.search-field {
  display: flex;
  align-items: center;
}

/* Styles for the input field */
.search-field .form-control {
  width: 100%;
}

/* Add CSS for suggestions dropdown */
.suggestions-dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  width: 500px;
  margin-top: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  top: 100%;
}

.suggestion-item {
  padding: 8px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

.search-input-container {
  position: relative;
}

.clear-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.clear-button:hover {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  box-shadow: none;
}

.clear-button i {
  color: #312e2e;
}

.addGigPhone input[type="tel"] {
  height: 55px;
  width: 100%;
}

.addGigEmail {
  margin-top: 37px;
}

#recipientsModal input::placeholder {
  font-size: 14px;
}


.whatsapp-chat-container {
  background: linear-gradient(to bottom, #f4f4f4, #e3e3e3);
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  max-height: 400px;
  overflow-y: scroll;
}

.whatsapp-message {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  clear: both;
  position: relative;

}

.whatsapp-sender-message {
  align-self: flex-end;
  border-radius: 10px;
  padding: 10px;
  margin-left: auto;
  word-wrap: break-word;
  margin-bottom: 5px;
}


.whatsapp-receiver-message {
  align-self: flex-start;
  border-radius: 10px;
  padding: 10px;
  margin-right: auto;
  word-wrap: break-word;
  margin-left: 10px;
  margin-bottom: 5px;
}


.messageContainer {
  min-width: 100px;

}

.message-timestamp {
  font-size: 12px;
  color: #888;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 0px solid transparent;
  border-top: 20px solid #dcf8c6;

}

.triangle2 {
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #ddd;

}

@media (max-width: 768px) {
  .messageForm {
    max-width: 100%;
    min-width: 100%;
    /* Adjust as needed for smaller screens */
  }
}

@media (min-width: 769px) {
  .messageForm {
    max-width: 100%;
    min-width: 100%;
    /* Adjust as needed for smaller screens */
  }
}

/* .searchParameters{
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin: 2%;
} */

/* @media screen and (max-width:768px){
  .searchParameters{
    display: contents;
  }
} */
/* ------------- analytics style starts ---------------- */

/* Default styles for cards */
.card.analyticCards {
  margin: 5px;

}

.card.recruiterAnalyticsCards {
  width: auto;
}

.analytics-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* Full width on small screens */
}

/* Media query for screens up to 768px (mobile) */
@media (max-width: 768px) {
  .card.analyticCards {
    width: 100%;
    /* Full width on small screens */
  }
}

/* Media query for screens between 768px and 1200px (tablet) */
@media (min-width: 768px) and (max-width: 1200px) {
  .card.analyticCards {
    width: 48%;
    /* Half width on medium-sized screens */
  }
}

/* Media query for screens larger than 1200px (desktop) */
@media (min-width: 1200px) {
  .card.analyticCards {
    width: 32.33%;
    /* One-third width on large screens */
  }
}

/* ------------- analytics style starts ---------------- */
/*-----------Apply job starts -----------------*/
/* .hero-container {
  position: relative;
  height: 400px;

  color: white;

}
*/
/* .hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
*/
.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

}

/* .hero-overlay {
  min-height: 100vh;
  background-image: linear-gradient(to top, rgb(8, 8, 8)10%, rgba(52, 51, 51, 0.4)100%), url("../assets/images/HealthCare5.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #0b0b0b;
  width: 100%;
  overflow: hidden;
}*/
.hero-overlay {
  /* min-height: 80vh; */
  height: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.hero-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;
}



.hero-overlay img {
  width: 100%;
  height: 330px;
  object-fit: fill;
  position: absolute;
  opacity: 0;
  animation: slide 30s infinite;
  /* top: -5px; */
  z-index: -1;
}

.hero-content {
  z-index: 3;
}

.hero-overlay img:nth-child(1) {
  animation-delay: 0s;
}

.hero-overlay img:nth-child(2) {
  animation-delay: 10s;
}

.hero-overlay img:nth-child(3) {
  animation-delay: 20s;
}

@keyframes slide {

  0%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }
}

.section {
  padding-top: 5rem;
}

/* .hero-content {
  color: white;
} */

.font-22 {
  font-size: 22px;
}

.font-14 {
  font-size: 14px;
}

.font-white {
  color: white;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.d-flex {
  display: flex;
}

.mb-5 {
  margin-bottom: 5px;
}


.footer-container {
  position: static;
  bottom: 0;
  width: 100%;
  margin-top: 20px;
}

/* @media (max-width: 1400px) {
  .footer-container {
    position: static;   
  }
} */

.column-container {
  margin-left: 2rem;
}

@media (min-width: 850px) {
  .column-container {
    display: flex;
  }
}

/*----------Apply job ends --------------*/

/*----------Apply job ends --------------*/
.mainSec {
  position: relative;
  height: 60vh;
}

.mainSec2 {
  position: relative;
  height: auto;
  min-height: 87vh;
}

.mainSec2_job {
  min-height: 63vh;
}

.navSec {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: #ffff;
}

.jobListImage {

  width: 100%;
  height: 60vh;
  color: #ffff;
  background-image: linear-gradient(to top, rgb(21, 21, 21, 0.7)0%, rgb(10, 10, 10, 0.1)100%), url("../assets/images/high5people.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
  margin: 0px 0px 30px 0px;
}

.landingSec_,
.landingSec_Accounting,
.landingSec_Administrative,
.landingSec_Clinical,
.landingSec_Customer,
.landingSec_Data,
.landingSec_Engineering,
.landingSec_Human,
.landingSec_Legal,
.landingSec_Manufacturing,
.landingSec_Marketing,
.landingSec_Sales,
.landingSec_Talent,
.landingSec_Other {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60vh;
  color: #ffff;
  background-image: linear-gradient(to top, rgb(21, 21, 21, 0.7)0%, rgb(10, 10, 10, 0.1)100%), url("../assets/images/high5people.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
  margin: 0px;
}

.landingSec_Healthcare {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60vh;
  color: #ffff;
  background-image: linear-gradient(to top, rgb(21, 21, 21, 0.7)0%, rgb(10, 10, 10, 0.1)100%), url("../assets/images/3.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
  margin: 0px;
}

.landingSec_Information {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60vh;
  color: #ffff;
  background-image: linear-gradient(to top, rgb(21, 21, 21, 0.7)0%, rgb(10, 10, 10, 0.1)100%), url("../assets/images/informationTech.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
  margin: 0px;
}

.landingContent {
  z-index: 1;
}

.referBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 38px;
  grid-gap: 10px;
  gap: 10px;
  width: 172px !important;
  height: 40px;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.joinCommununityHero {
  top: 0;
  left: 0;
  width: 100%;
  height: 60vh;
  color: #ffff;
  background-image: linear-gradient(to top, rgb(21, 21, 21, 0.7)0%, rgb(10, 10, 10, 0.1)100%), url("../assets/images/high5people.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
  margin: 0px;
}

.careerPageNewBackground {
  top: 0;
  left: 0;
  width: 100%;
  height: 60vh;
  color: #ffff;
  background-image: linear-gradient(to top, rgb(21, 21, 21, 0.7)0%, rgb(10, 10, 10, 0.1)100%), url("../assets/images/high5people.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
  margin-bottom: 10px;
}

/*************Keyword Filter**************/
.array-container {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.array-element {
  margin-right: 10px;
  margin-bottom: 10px;
}

.tag-row {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  margin-right: 5px;
  margin-bottom: 5px;
}



.btn-text {
  margin-left: 5px;
}

/*************Keyword Filter**************/
/********Email Template ************/

.activeTemplate {
  position: absolute;
  top: 0px;
  right: -12px;
  padding: 3px;
  -moz-border-radius: 250px;
  -webkit-border-radius: 250px;
  border-radius: 250px;
  background: #49d659;
  display: block;
  border: 2px solid #49d659;
}

.pausedTemplate {
  position: absolute;
  top: 0px;
  right: -10px;
  padding: 3px;
  -moz-border-radius: 250px;
  -webkit-border-radius: 250px;
  border-radius: 250px;
  background: #6c6c6c;
  display: block;
  border: 2px solid #6c6c6c;
}


/***********Email Template ***********/

#screeningSelect {

  background-color: white;
  border: 1px solid #727475;
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
  display: inline;
  padding: 0px 2px;
  width: auto;

}

#screeningSelectLoc {
  background-color: white;
  border: 1px solid #727475;
  border-radius: 4px;
  margin-left: 3px;
  margin-right: 3px;
  display: inline;
  padding: 0px 0px;
  width: auto;
}

.custom-google-location {
  /* Apply styles to the outermost container of GoogleLocationCountryAPI */
  font-size: 14px;
  border: 1px solid #ccc;
  height: 45px;
}

.custom-google-location input[type="text"] {
  /* Apply styles to the input element inside GoogleLocationCountryAPI */
  height: 45px;
  border-radius: 4px;
  padding: 15px;

}

.loading-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-text {
  font-size: 12px;
  animation: blink 1s infinite;
  color: "grey",

}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

@media screen and (max-width: 1023px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}


.no-border .ql-container.ql-snow {
  border: none;
  /* Remove the border around the editor */
}

.no-border .ql-editor {
  border: none;
  /* Remove any inner borders within the editor */
}

.bordered .ql-container.ql-snow {
  border: 1px solid #ccc;
  /* Add a border around the editor */
}

.bg--lite--info {

  width: 40px;
  height: 40px;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #aef3ec;
}

.text--info {
  color: #299cdb;
}

.bg--lite--success {

  width: 40px;
  height: 40px;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d0f7e1;
}

.text--success {
  color: rgb(3 201 136);
}

.bg--lite--pending {

  width: 40px;
  height: 40px;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff8dd;
}

.bg--lite--failed {

  width: 40px;
  height: 40px;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff5f8;
}

/*------------communication module start----------------*/
#communicationOverview,
#communicationModal {
  font-family: "Noto Sans", "Arial", sans-serif;
}

#communicationMainHeading {
  font-family: "Noto Sans", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 900;
}

/* Media query for tablet-sized screens */
@media (min-width: 1024px) {
  .emailOverviewCards {
    width: calc(33% - 15px);
    /* Adjust the width as needed */
  }

  .messageOverviewCards {
    width: calc(26% - 15px);
    /* Adjust the width as needed */
  }
}

/* Media query for tablet-sized screens */
@media (max-width: 1023px) {

  .emailOverviewCards,
  .messageOverviewCards {
    width: calc(50% - 15px);
    /* Adjust the width as needed */
  }
}

/* Media query for mobile-sized screens */
@media (max-width: 767px) {

  .emailOverviewCards,
  .messageOverviewCards {
    width: calc(100% - 15px);
    /* Adjust the width as needed */
  }
}

/*------------communication module end----------------*/