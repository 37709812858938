

.form-group {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.labelAI {
    display: block;
    font-weight: bold;
    width: 30%;
}

.inputAI[type="text"],
.inputAI[type="number"],
.textareaAI,
.selectAI {
    width: 60%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.inputAIError{
    width: 60%;
    padding: 10px;
    border: 1px solid #bb3737;
    border-radius: 5px;
    box-sizing: border-box;
}

.inputAI[readonly] {
    background-color: #f2f2f2;
}




#cancelButton {
    background-color: #ccc;
    color: white;
}

#regenerateButton {
    background-color: #4CAF50;
    color: white;
}

#addQuestionsButton {
    background-color: #ffc107;
    color: white;
}

#proceedButton {
    background-color: #17a2b8;
    color: white;
}

#cancelSampleQuestions {
    background-color: #dc3545;
    color: white;
}



